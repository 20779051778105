import React, { Component } from 'react';
import { format } from 'date-fns'
import plLocale from 'date-fns/locale/pl'

import { withFirebase } from '../Firebase/FirebaseContext';

import { Card, Icon, Label, Message } from 'semantic-ui-react'


const INITIAL_STATE = {
  address: '',
  city: '',
  postCode: '',
  error: null,
  initFirebase: false,
  theUser: '',
  users: [],
};

class Reservations extends Component {
  constructor(props) {
    super(props);

    // this.state = { ...INITIAL_STATE };
    this.state = {
      address: '',
      city: '',
      postCode: '',
      error: null,
      initFirebase: false,
      user: '',
      users: [],
      offerName: '',
      reservationDate: '',
      tripDate: '',
      numberParticipants: null,
      price: null,
      noOffers: false
    }
  }

  // onSubmit = event => {
  //   const { address, city, postCode } = this.state;

  //   event.preventDefault();

  //   console.log(` LogName : `, event, address, city, postCode)
  //   this.props.firebase
  //     .doInfoUpdate(address, city, postCode)
  //     .then(() => {
  //       this.setState(() => ({ ...INITIAL_STATE }));
  //     })
  //     .catch(error => {
  //       this.setState({ error });
  //     });

    
  // };

  firebaseInit = () => {
    // const { users } = this.state
// console.log(this.props.firebase);

    const { firebase } = this.props

    let authUser = firebase.auth.currentUser.uid

    

    if (firebase && !this.state.initFirebase && this.state.users) {
      firebase.getUserReservations(authUser)
        .then(doc => {
          console.log(doc.data())
          let data = doc.data()
          this.setState({
            offerName: data.offerName,
            reservationDate: format(data.reservationDate.toDate(), `d MMMM YYYY`, { locale: plLocale}),
            tripDate: format(data.tripDate.toDate(), `d MMMM YYYY`, { locale: plLocale}),
            numberParticipants: data.numberParticipants,
            price: data.price,
          })
        }).catch(error => {
          this.setState({ noOffers: true })
        })
        this.setState({ 
          initFirebase: true,
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
    
  }

  componentDidUpdate() {
    this.firebaseInit();
    // console.log(` users : `, this.state.users)
  }



  render() {

    return (
<div>


    <Message
      icon='inbox'
      warning
      hidden={!this.state.noOffers}
      header='Brak rezerwacji'
      content='Po złożeniu rezerwacji, dane pojawią się tutaj.'
    />
  { !this.state.noOffers ? 
    <Card fluid>
      <Card.Content header={ this.state.offerName } meta={ `Data rezerwacji: ${this.state.reservationDate}`} />
      <Card.Content description={''} />
      <Card.Content extra>
        <Label.Group>
          <Label>
            <Icon name='user' />
            {this.state.numberParticipants} Uczestników
          </Label>
          <Label>
            <Icon name='calendar alternate outline' />
            Data wyjazdu: {this.state.tripDate}
          </Label>
        </Label.Group>
      </Card.Content>
    </Card>

    : null
  }
  

</div>
    
    );
  }
}

export default withFirebase(Reservations);


// var docRef = db.collection("cities").doc("SF");

// docRef.get().then(function(doc) {
//     if (doc.exists) {
//         console.log("Document data:", doc.data());
//     } else {
//         // doc.data() will be undefined in this case
//         console.log("No such document!");
//     }
// }).catch(function(error) {
//     console.log("Error getting document:", error);
// });