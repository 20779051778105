import * as routes from './routes'

const Main = (data) => {

  const offers = data.allDatoCmsOffer.edges

let tcat = [
  { node: {
    categories: [{name: `LATO`}]
  }},
  { node: {
    categories: [{name: `ZIMA`}]
  }},
  { node: {
    categories: [{name: `WYCIECZKI SZKOLNE`}]
  }},
  { node: {
    categories: [{name: `ZIELONE SZKOŁY`}]
  }},
  { node: {
    categories: [{name: `PÓŁKOLONIE`}]
  }},
  { node: {
    categories: [{name: `ZIMA W MIEŚCIE`}]
  }},
  { node: {
    categories: [{name: `WYJAZDY INTEGRACYJNE`}]
  }},
  { node: {
    categories: [{name: `TURNIEJE SPORTOWE`}]
  }},
  { node: {
    categories: [{name: `SEKCJE SPORTOWE: KORFBALL`}]
  }},
  { node: {
    categories: [{name: `SEKCJE SPORTOWE: PIŁKA NOŻNA`}]
  }},
  { node: {
    categories: [{name: `SEKCJE SPORTOWE: ROLKI`}]
  }},
  { node: {
    categories: [{name: `SEKCJE SPORTOWE: UNIHOKEJ`}]
  }},
  { node: {
    categories: [{name: `SEKCJE SPORTOWE: TRENERZY`}]
  }}
]

  let categories = []
  let subCategories = []
  
  offers.map((item, index) => {
    item.node.categories.map((catItem) => {
      if (!categories.some(e => e.name === catItem.name)) {

        let category = catItem.name.split(': ')

        if (category.length > 1) {
          
          if (!subCategories.some(e => e.name === category[0])) {

            let newSubCat = {
              name: category[0],
              categories: [{
                name: category[1],
                link: routes.OFFERS,
                caption: category[1],
              }]
            }
            subCategories.push(newSubCat)
          } else {

            let meow = {
              name: category[1],
              link: routes.OFFERS,
              caption: category[1],
            }
            let filt = subCategories.filter(obj => obj.name === category[0])            
            filt[0].categories.push(meow)

          }
        } else {

          let tempCategory = {
            name: catItem.name,
            link: routes.OFFERS,
            caption: catItem.name,
          }
          categories.push(tempCategory)
        }

      }
    })
  })

  categories.push(subCategories)

  // console.log(`categories: `, categories);
  


  // const offers = data.allDatoCmsOffer.edges.map(({node}) => ({
  //   ...node
  // }))

  let Menu = [
    {
      name: "offers",
      link: routes.OFFERS,
      caption: "Oferty",
      subitems: categories
    },
    {
      name: "resorts",
      link: routes.RESORTS,
      caption: "Ośrodki",
      subitems: []
    },
    {
      name: "gallery",
      link: routes.GALLERY,
      caption: "Galeria",
      subitems: []
    },
    {
      name: "about",
      link: routes.ABOUT,
      caption: "O Nas",
      subitems: []
    },
    {
      name: "kadra",
      link: routes.KADRA,
      caption: "Kadra",
      subitems: []
    },
    {
      name: "opinions",
      link: routes.OPINIONS,
      caption: "Opinie",
      subitems: []
    },
    {
      name: "downloads",
      link: routes.DOWNLOADS,
      caption: "Dokumenty do pobrania",
      subitems: []
    },
    /* {
      name: "forparents",
      link: routes.FORPARENTS,
      caption: "Strefa Rodzica",
      subitems: [
        {
          name: "downloads",
          link: routes.DOWNLOADS,
          caption: "Dokumenty do pobrania",
        },
        {
          name: "conditions",
          link: routes.CONDITIONS,
          caption: "Warunki uczęstnictwa",
        },
        {
          name: "promo",
          link: routes.PROMO,
          caption: "Promocje",
        },
        {
          name: "faq",
          link: routes.FAQ,
          caption: "Odpowiedzi na pytania rodziców",
        },
      ]
    }, */
/*     {
      name: "attractions",
      link: routes.ATTRACTIONS,
      caption: "Atrakcje",
      subitems: []
    }, */
    {
      name: "contact",
      link: routes.CONTACT,
      caption: "Kontakt",
      subitems: []
    }
  ]

  return Menu
  
}

export { Main }