import React, { Component, Fragment } from 'react';
import getFirebase from './Firebase';

import FirebaseContext from './Firebase/FirebaseContext';
import withAuthentication from './Session/withAuthentication';

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import CookiesPopup from '../components/CookiesPopup'

import Sentry from '../components/Utilities/Sentry'

import 'semantic-ui-css/semantic.min.css'

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');
    const firestore = import('firebase/firestore');

    Promise.all([app, auth, database, firestore]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <Sentry>
          <CookiesPopup />
          <AppWithAuthentication {...this.props} />
        </Sentry>
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(props => (
  <Fragment>
    <Navigation>
      {props.children}
      <Footer />
    </Navigation>
  </Fragment>
));

export default Layout;