import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Accordion, Menu, Container, Sidebar, Icon, Responsive, Segment } from 'semantic-ui-react'

import AuthUserContext from '../Session/AuthUserContext';
import { Main } from '../../constants/menu'
import * as routes from '../../constants/routes';
import HeaderKontakt from '../HeaderKontakt'
import Logo from '../Header/Logo'
import { SignOut } from '../Account'

const style = {
  accordion: {
    border: 'none',
    borderRadius: '0px',
    fontFamily: 'Playfair Display',
    fontWeight: '700',
  },
  accordionMain: {
    boxShadow: 'none',
    fontSize: '1.2rem',
  },
  accordionTitle: {
    color: 'rgba(0,0,0,0.9)'
  },
  activeMain: {
    borderLeft: `6px solid rgba(255,225,31,1)`
  },
  menu: {
    fontFamily: 'Playfair Display',
    fontSize: '1.2rem',
    fontWeight: '700',
    marginTop: '0px',
    borderRadius: '0px'
  }
}

class MobileContainer extends Component {
  state = { activeIndex: 0 }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { activeIndex, sidebarOpened } = this.state
    const { children, data } = this.props
    return (
      <AuthUserContext.Consumer>
        {authUser =>
        <div>
          <Responsive as={Sidebar.Pushable} >
            <Sidebar
              as={Menu}
              animation='push'
              direction='right'
              onHide={this.handleSidebarHide}
              vertical
              visible={sidebarOpened}
              style={style.menu}
            >
            <Menu.Item as={Link} to="/" header>
              <Logo />
            </Menu.Item>
              { Main(data).map(( item, index ) => (
                item.subitems.length > 0 ? 
                <Menu.Menu key={ index }>
                  <Accordion as={Menu} vertical styled
                    style={Object.assign({}, style.accordion, style.accordionMain)}
                  >
                    <Accordion.Title
                      content={item.caption}
                      index={0}
                      style={Object.assign({}, style.accordion, style.accordionTitle)}
                    />
                      { item.subitems.map(( sItem, sIndex ) => (
                        <Accordion.Content key={ sIndex } active={activeIndex === 0} fluid='true' style={{ padding: '0.2em 1em 0.5em', fontSize: '1rem'}}>
                          <Menu.Item
                            as={Link}
                            to={sItem.link}
                            activeStyle={style.activeMain}
                            style={{ fontSize: '1rem', color: 'rgba(0,0,0,0.9)' }}
                            state={{ source: sItem.caption }}
                            >
                            { sItem.caption }
                          </Menu.Item>
                        </Accordion.Content>
                      ))}
                    </Accordion>
                  </Menu.Menu>
                :
                <Menu.Item key={ index } as={Link} to={item.link} activeStyle={style.activeMain}>
                  { item.caption }
                </Menu.Item>
              ))}
              { authUser ? 
              <div>
                <Menu.Item as={Link} to={routes.ACCOUNT} activeStyle={style.activeMain}>
                  Twoje Konto
                </Menu.Item>
                <Menu.Item>
                  <SignOut />
                </Menu.Item>
                </div>
                :
                <Menu.Item as={Link} to={routes.SIGN_IN} activeStyle={style.activeMain}>
                  Zaloguj Się
                </Menu.Item>
              }
            </Sidebar>
            <Sidebar.Pusher dimmed={sidebarOpened}>
                <HeaderKontakt />
                <Segment
                  basic
                  secondary
                  textAlign='center'
                  style={{ backgroundColor: "white", padding: '0px' }}
                  vertical
                >
                  <Container>
                    <Menu secondary>
                      <Menu.Item as={Link} to="/" header>
                        <Logo />
                      </Menu.Item>
                      <Menu.Item position='right' onClick={this.handleToggle}>
                        <Icon name='sidebar' size='large'/>
                      </Menu.Item>
                    </Menu>
                  </Container>
                </Segment>
              {children}
            </Sidebar.Pusher>
          </Responsive>
          </div>
        }
      </AuthUserContext.Consumer>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

export default MobileContainer
