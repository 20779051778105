import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from "react-cookie-consent";

import './styles.css'

const CookiePopup = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsHomePage {
        gdprBanner
        gdprBannerNode {
          childMarkdownRemark {
            html
          }
        }
      }  
    }
  `}
  render={data => {
    const { html } = data.datoCmsHomePage.gdprBannerNode.childMarkdownRemark
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Akceptuje"
          cookieName="zgoda-na-przetwarzanie-danych"
          buttonClasses="gdpr gdpr__button"
          buttonStyle={{ color: "#2B3572", fontSize: "16px", marginRight: '8rem' }}
          expires={150}
          contentClasses="gdpr"
          containerClasses="gdpr__wrapper"
          style={{ backgroundColor: '#2B3572', fontSize: '18px' }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </CookieConsent>
  </div>

    )
  }}
  />
)

export default CookiePopup