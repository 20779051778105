import styled from "styled-components";

const StyledContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 960px;
    padding: 0px 1.0875rem 1.45rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
`

export default StyledContentWrapper