import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import logo from '../../images/kapiteam-logo-lg.png'

import { Grid, Header } from '../Elements/'

import { Segment, Container, List, Icon, Item } from 'semantic-ui-react'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query pageQ {
        allSitePage  {
          edges {
            node {
              id
              context {
                uid
                pageType
                pageLink
              }
            }
          }
        }
        datoCmsContactInfo {
          id
          emailPrimary
          phonePrimary
          phoneSecondary
          location {
            id
            name
            street
            city
            geolocation {
              latitude
              longitude
            }
          }
          socialFacebook
          socialInstagram
          socialYoutube
        }
      }
    `}
    render={data => {
      
      const { phonePrimary, phoneSecondary, emailPrimary, socialFacebook, socialInstagram, socialYoutube } = data.datoCmsContactInfo

      return (

        <div>
          <Segment inverted vertical style={{ padding: '5em 0em', color: '#2B3572' }}>
          <Container>
            <Grid divided footer='true' inverted stackable>
              <Grid.Row>
                <Grid.Column width={7} floated='left'>
                  <Item.Group>
                    <Item>
                      <Item.Image
                        size="tiny"
                        src={logo}
                        style={{ marginRight: '1.5em', maxHeight: '100px', maxWidth: '100px' }}
                        alt="Logo Kapiteam"
                      />
                      {/* <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' /> */}
                      <Item.Content verticalAlign='middle'>
                        <Header title='true' style={{ color: '#FFFBF9', fontFamily: 'Bangers', fontWeight: '400' }}>Kapiteam Piotr Bachurski</Header>
                      </Item.Content>
                    </Item>
                    <Item>
                      
                      {/* <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' /> */}
                      <Item.Content verticalAlign='middle'>
                  KOLONIE - OBOZY - ZIMOWISKA - WCZASY RODZINNE - WYJAZDY INTEGRACYJNE - WYCIECZKI SZKOLNE

                      </Item.Content>
                    </Item>
                    <Item>
                      
                      {/* <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' /> */}
                      <Item.Content verticalAlign='middle'>
                        <Icon link name='facebook square' size='big' style={{ padding: '0 12px 0 0' }} to={ socialFacebook }/>
                        <Icon link name='instagram' size='big' style={{ padding: '0 12px 0 6px' }} to={ socialInstagram }/>
                        <Icon link name='youtube' size='big' style={{ padding: '0 12px' }} to={ socialYoutube }/>
                      </Item.Content>
                    </Item>


                  </Item.Group>

                </Grid.Column>
                <Grid.Column width={3} floated='right'>
                  <Header title='true' footer='true' as='h4' style={{ color: '#FFFBF9', letterSpacing: '1px' }}>Firma</Header>
                  <List link inverted>
                    {/* { data.allSitePage.edges.map(( node, index ) => (
                      node.node.context.pageType === null ?
                        node.node.context.pageType !== 'offer' || 'result' ? 
                          <List.Item key={ index } as={Link} to={ node.node.context.uid }>
                            { node.node.context.uid }
                          </List.Item>
                        : null
                      : null
                    ))} */}
                    <List.Item as={Link} to='/oferty'>Oferty</List.Item>
                    <List.Item as={Link} to='/osrodki'>Ośrodki</List.Item>
                    <List.Item as={Link} to='/o-nas'>O Nas</List.Item>
                    <List.Item as={Link} to='/kontakt'>Kontakt</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3} floated='right'>
                  <Header title='true' footer='true' as='h4' style={{ color: '#FFFBF9', fontWeight: '400', letterSpacing: '1px' }}>Kontakt</Header>
                  <List link inverted>

                    { data.datoCmsContactInfo.location.map(( item, index ) => (
                      <div key={ index }>
                        <List.Item icon='marker' content={ item.street } />
                        <List.Item content={ item.city } />
                        <List.Item content='' />
                      </div>
                    ))}
                    <List.Item icon='mail' as='a' href={ `mailto:${emailPrimary}` } content={ emailPrimary } />
                    <List.Item icon='phone' as='a' href={ `tel:${phonePrimary}` } content={ phonePrimary } />
                    <List.Item icon='phone' as='a' href={ `tel:${phoneSecondary}` } content={ phoneSecondary } />
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Grid centered subfooter='true' verticalAlign='middle'>
          <Grid.Row columns={2} >
            <Grid.Column textAlign='center'>
              Wykonanie: <a href='https://mbachurski.com'> Mariusz Bachurski</a>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <List horizontal inverted link>
                { data.allSitePage.edges.map(( node, index ) => (
                  node.node.context !== null ?
                    node.node.context.pageType === 'infoPage' ? 
                      <List.Item key={ index } as={Link} to={ `/${node.node.context.uid}` }>
                        { node.node.context.pageLink }
                      </List.Item>
                    : null
                  : null
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* <Segment.Group basic style={{ backgroundColor:  '#2B3572', marginTop: '0px', borderRadius: '0px' }}>
          <Segment basic compact padded inverted style={{ backgroundColor:  '#2B3572', marginTop: '0px' }}>
          </Segment>
          <Segment basic compact padded inverted style={{ backgroundColor:  '#2B3572', marginTop: '0px' }}>
           
          </Segment>
        </Segment.Group> */}

    </div>
        )
    }}
  />
)

export default Footer