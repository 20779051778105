import React from 'react'
import { Image } from 'semantic-ui-react'

import logo from '../../images/kapiteam-logo-sm.png'

const Logo = () => (
  <Image
    size="tiny"
    src={logo}
    style={{ marginRight: '1.5em', maxHeight: '85px', maxWidth: '80px' }}
    alt="I love Lamp"
  />
)

export default Logo
