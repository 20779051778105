import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import { Colors, Font } from './theme'

const Family = Font.Family
const Size = Font.Size

const StyledHeader = styled(Header)`
  &&& {
    
    color: ${props => props.sectiontitle || props.blocky ? props.titledark || props.titlepadded ? Colors.darkBlue : Colors.Heading : props.address || props.title ? Colors.SubHeading : props.button ? Colors.offWhite : Colors.darkBlue };
    
    ${'' /* color: ${props => 
      { if (props.sectiontitle || props.blocky || props.titlepadded) {
          return Colors.darkBlue
        } else if (props.address || props.title) {
          return Colors.SubHeading
        } else if (props.button) {
          return Colors.offWhite
        }
      }
    } */}


    font-family: ${props => props.sectiontitle || props.blocky || props.footer || props.titlepadded ? Family.Heading : Family.SubHeading }
    font-size: ${props => props.sectiontitle ? Size.Heading : props.address || props.button ? Size.Contact : Size.SubHeading }
    padding: ${props => props.padding ? '100px' : props.address || props.button ? '2px' : props.title ? '0px' : '15px'};
    padding-top: ${props => props.sectiontitle || props.titlepadded ? '6rem' : 'inherit' }
    padding-bottom: ${props => props.titlepadded || props.spaced ? '3rem' : 'inherit' }
    text-align: ${props => props.sectiontitle ? 'center' : 'inherit' }
    text-shadow: ${props => props.sectiontitle ? '1px 1px 6px rgba(100, 100, 100, 0.7)' : '0px 0px 0px rgba(100, 100, 100, 0)' };
    font-weight: ${props => props.title || props.titlepadded ? '700' : '400'}
    margin-top: ${props => props.address ? '4px' : props.button ? '0px' : '0px'}
    margin-bottom: ${props => props.margin ? '15px' : '0px'}
  }
`

export default StyledHeader

StyledHeader.propTypes = {
  // address: PropTypes.bool,
  // button: PropTypes.bool,
  // footer: PropTypes.bool,
  // margin: PropTypes.bool,
  // padding: PropTypes.bool,
  // sectiontitle: PropTypes.bool,
  // spaced: PropTypes.bool,
  // title: PropTypes.bool,
  // titledark: PropTypes.bool,
  // titlepadded: PropTypes.bool
}

// color: ${props => { 
    
//   switch (props) {
//     case props.sectionTitle || props.blocky:
//       return Color.Heading
//     case props.titleDark:
//       return Color.darkBlue
//     case props.address || props.title:
//       return Color.SubHeading
//     case props.button:
//       return Color.offWhite
//     default:
//       return (
//         'initial'
//       )
//   } 
// }
// }