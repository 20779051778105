import React, { Component } from 'react'
import { navigate } from 'gatsby'

import * as routes from '../../constants/routes'
import { withFirebase } from '../Firebase/FirebaseContext'

import { Button, Form, Segment, Message } from 'semantic-ui-react'

import FormField from '../Partials/FormField'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
}

class SignInForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        navigate(routes.ACCOUNT);
      })
      .catch(() => {
        this.setState({
          error: true
        })
      })
    event.preventDefault()
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
    if (this.state.error) {
      this.setState({ error: false })
    }
  }

  render() {
    const { error } = this.state
    return (
      <Form onSubmit={this.onSubmit} autoComplete="on">
        <Segment>
          <FormField name="email" label="Email" action={e => this.handleChange(e)} focus />
          <FormField name="password" label="Hasło" action={e => this.handleChange(e)} />
          <Button type="submit" color="blue">
            Zaloguj Się
          </Button>
          <Message negative hidden={!error}>
            <Message.Header>Wystąpił błąd</Message.Header>
            <p>Podany adres e-mail lub hasło nie jest prawidłowe. Prosimy sprawdzić detale, i spróbować ponownie.</p>
          </Message>
        </Segment>
      </Form>
    )
  }
}

export default withFirebase(SignInForm)