import React from 'react'

import { Button } from 'semantic-ui-react'

import { withFirebase } from '../Firebase/FirebaseContext'

const SignOutButton = ({ firebase }) => (
  <Button basic color='teal' type="button" onClick={firebase.doSignOut}
    style={{
      fontFamily: 'Playfair Display',
      fontWeight: '400',
    }}>
    Wyloguj Się
  </Button>
);

export default withFirebase(SignOutButton)