import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import FormField from '../Partials/FormField'

import { Button, Form, Segment, Message } from 'semantic-ui-react'

const INITIAL_STATE = {
  email: '',
  done: true,
  error: false,
  errMessage: '',
  success: false
}

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email } = this.state
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ 
          ...INITIAL_STATE,
          success: true
        }))
        setTimeout(() => { navigate(routes.SIGN_IN) }, 5000)
      })
      .catch(error => {  
        let errMessage
        if (error.code === 'auth/invalid-email' || 'auth/user-not-found') {
          errMessage = 'Wpisany e-mail nie istnieje'
        }
        this.setState({ 
          errors: error,
          error: true,
          errMessage: errMessage
        })
      })
    event.preventDefault()
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ 
      [name]: value,
      error: false,
      errMessage: ''
    })
  }

  render() {
    const { error, errMessage, success } = this.state
    return (
      <Form onSubmit={this.onSubmit}>
      <Segment>
        <FormField name="email" label="Email" action={e => this.handleChange(e)} focus />
        <Button type="submit" color="blue">
          Zresetuj hasło
        </Button>
        <Message 
          negative 
          hidden={!error}
          header="Wystąpił błąd"
          content={errMessage}
        />
        <Message
          positive
          hidden={!success}
          header="Sukces"
          content="Prosimy o sprawdzenie poczty z dalszymi instrukcjami do zresetowania hasła."
        />
      </Segment>
    </Form>
    )
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={routes.PASSWORD_FORGET}>Zresetuj hasło?</Link>
  </p>
)

export { PasswordForgetLink }

export default withFirebase(PasswordForgetForm)