import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import FormField from '../Partials/FormField'

import { Button, Checkbox, Form, Loader, Message, Segment } from 'semantic-ui-react'

const INITIAL_STATE = {
  name: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  privacyCheck: false,
  error: null,
  errMessage: '',
  success: false
};

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
    if (this.state.error) {
      this.setState({ error: false })
    }
  }

  handleCheck = () => {
    this.setState({ 
      privacyCheck: !this.state.privacyCheck,
      error: this.state.privacyCheck ? true : false,
      errMessage: 'Prosimy o wyrażenie zgody przed wysłaniem formularza.'
    })
  }

  onSubmit = event => {
    const { name, email, passwordOne, passwordTwo, privacyCheck } = this.state
    const { firebase } = this.props

    event.preventDefault();

    if (privacyCheck === true) {
      if (passwordOne === passwordTwo) {
        firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(authUser => {
          firebase.doCreateUser(authUser.user.uid, name, email, privacyCheck)
        }).then(() => {
          this.setState({success: true })
          setTimeout(() => { navigate(routes.ACCOUNT) }, 2000)
        })
        .catch(() => {
          this.setState({ 
            error: true,
            errMessage: 'Wybrany adres e-mail już istnieje!'
          })
        })
      } else {
        this.setState({ 
          error: true,
          errMessage: 'Wpisane hasła powinny być identyczne. Prosimy spróbować ponownie.'
        })
      }
    } else {  
      this.setState({ 
        error: true,
        errMessage: 'Prosimy o wyrażenie zgody na kontakt przed wysłaniem formularza.'
      })
    }
  }

  render() {
    const { error, errMessage, success, privacyCheck } = this.state
    return (
      <Form onSubmit={this.onSubmit}>
        <Segment>
          <FormField name="name" type="text" label="Imię i nazwisko" action={e => this.handleChange(e)} focus />
          <FormField name="email" label="Email" action={e => this.handleChange(e)} />
          <FormField name="passwordOne" type="password" label="Hasło" action={e => this.handleChange(e)} noAutoComplete />
          <FormField name="passwordTwo" type="password" label="Powtóż hasło" action={e => this.handleChange(e)} noAutoComplete />
          <Form.Field>
            <Checkbox 
              label='Wyrażam zgodę na przetwarzanie danych lub kontakt mailowy lub telefoniczny ze strony pracownika odpowiednich działów Kapiteam.'
              checked={privacyCheck}
              onChange={this.handleCheck}  
            />
          </Form.Field>
          <Button type="submit" color="blue">
            Zarejestruj się
          </Button>
          <Message negative hidden={!error}>
            <Message.Header>Wystąpił błąd</Message.Header>
            <p>{errMessage}</p>
          </Message>
          <Message positive hidden={!success}>
            <Loader active inline='centered' size='medium'>
              <Message.Header>Rejestracja powiodła się!</Message.Header>
            </Loader>
          </Message>
        </Segment>
      </Form>
    )
  }
}

const SignUpLink = () => (
  <p>
    Nie masz jeszcze konta? <Link to={routes.SIGN_UP}>Zarejestruj się</Link>
  </p>
)

export { SignUpLink }

export default withFirebase(SignUpForm)