import styled from "styled-components";
import PropTypes from 'prop-types'
import { Button } from "semantic-ui-react";
import { FontFamily } from './theme'

// export const StyledButton = styled(Button)`
//   &&& {
//     color: palevioletred;
//     font-family: ${props => props.calltoaction ? FontFamily.SubHeading : 'initial' }
//     font-weight: bold;
//     padding: ${props => props.calltoaction ? '12px' : '50px'}
//   }
// `

// export const Sea = styled(Button)`
//   &&& {
//     color: ${ FontColor.Heading };
//     font-weight: bold;
//     font-family: ${ FontFamily.SubHeading }

//     padding: 50px;
//   }
// `

const StyledButton = styled(Button)`
  &&& {
    ${'' /* font-weight: bold; */}
    font-family: ${ FontFamily.SubHeading }
    padding: ${props => props.calltoaction ? '15px 30px' : '50px'}
  }
`

export default StyledButton

// StyledButton.propTypes = {
//   calltoaction: PropTypes.bool
// }