import styled from "styled-components";
import { Segment } from "semantic-ui-react"
import {  Colors, Font } from './theme'

const Family = Font.Family
const Size = Font.Size

const StyledSegment = styled(Segment)`
  &&& {
    background-color: ${props => props.quote || props.features ? Colors.lightYellow : Colors.mainBG };
    border: none;
    border-left: ${props => props.quote ? '6px solid ${ Colors.yellow }' : Colors.mainBG };
    margin-bottom: ${props => props.features ? '0px' : 'initial'}
    padding-left: 2rem;
    h2 {
      font-family: ${ Family.SubHeading }
      font-size: ${ Size.SubHeading }
    }
    h5 {
      font-family: ${ Family.SubHeading }
      font-size: 1.4rem;
    }
    li {
      font-size: 1.2rem;
      list-style-type: circle;
      line-height: 1.5;
    }
  }
`

export default StyledSegment