import React from 'react'

const Icons = props => {

    let Icon

    switch (props.icon) {
      case 'icon_smile':
        Icon = (
          <svg width="66" height="56" viewBox="0 0 66 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.473 28.1997C19.398 28.1997 19.323 28.1997 19.245 28.1997C15.84 28.1997 13.0815 31.1142 13.0815 34.7097C13.0815 38.3052 15.84 41.2212 19.245 41.2212C19.3215 41.2212 19.395 41.2212 19.473 41.2212V28.1997Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.473 36.9889C19.473 36.9889 18.624 34.6519 16.878 34.3174" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M57.678 28.1997C57.7545 28.1997 57.828 28.1997 57.9075 28.1997C61.311 28.1997 64.071 31.1142 64.071 34.7097C64.071 38.3052 61.311 41.2212 57.9075 41.2212C57.831 41.2212 57.7575 41.2212 57.678 41.2212V28.1997Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M57.678 36.9889C57.678 36.9889 58.527 34.6519 60.2746 34.3174" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M57.678 28.1999V40.0754C57.6784 42.5842 57.1846 45.0685 56.2248 47.3865C55.265 49.7045 53.858 51.8107 52.0841 53.5848C50.3102 55.359 48.2043 56.7663 45.8864 57.7265C43.5686 58.6867 41.0844 59.1809 38.5755 59.1809V59.1809C33.5092 59.1809 28.6504 57.1683 25.068 53.5859C21.4856 50.0035 19.473 45.1447 19.473 40.0784V28.1999C19.473 28.1999 52.692 29.2274 52.692 14.3999C52.692 14.4104 57.678 17.6774 57.678 28.1999Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.583 38.9463C62.0232 39.6506 61.3125 40.2203 60.5034 40.6136C59.6942 41.0069 58.8072 41.2137 57.9075 41.2188C57.831 41.2188 57.7575 41.2188 57.678 41.2188L57.6345 41.3538C57.4337 44.388 56.5111 47.3303 54.9436 49.936C53.3761 52.5416 51.2089 54.7353 48.6225 56.3343C49.7542 57.3307 51.0761 58.0875 52.5084 58.5588C53.9407 59.0301 55.4537 59.2062 56.956 59.0765C58.4582 58.9469 59.9186 58.514 61.249 57.8043C62.5793 57.0945 63.752 56.1224 64.6962 54.9468C65.6404 53.7712 66.3366 52.4164 66.7426 50.9642C67.1487 49.5121 67.2562 47.9927 67.0587 46.4978C66.8612 45.0029 66.3628 43.5636 65.5935 42.2667C64.8243 40.9699 63.8002 39.8424 62.583 38.9523V38.9463Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.473 28.2004C19.473 28.2004 24.168 25.2784 26.877 21.7969" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.95 18.9136C40.95 18.9136 38.6535 23.4421 32.385 27.4636" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M38.5755 1.59766C12.615 1.59766 14.442 30.6272 14.442 30.6272C14.9994 29.8782 15.723 29.2688 16.556 28.8472C17.3889 28.4255 18.3084 28.203 19.242 28.1972C19.3185 28.1972 19.392 28.1972 19.47 28.1972C19.47 28.1972 52.689 29.2247 52.689 14.3972C52.689 14.3972 57.675 17.6642 57.675 28.1972C57.7515 28.1972 57.825 28.1972 57.9045 28.1972C58.7782 28.2016 59.6404 28.3964 60.4311 28.7682C61.2217 29.1399 61.9218 29.6796 62.4825 30.3497C62.4825 30.3497 64.536 1.59766 38.5755 1.59766Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.5165 41.3503L19.473 41.2123C19.398 41.2123 19.323 41.2123 19.245 41.2123C18.3112 41.206 17.3916 40.983 16.5586 40.5608C15.7257 40.1386 15.0021 39.5287 14.445 38.7793C13.1889 39.6498 12.125 40.769 11.3192 42.0676C10.5135 43.3662 9.98309 44.8165 9.76092 46.3285C9.53874 47.8405 9.62952 49.3821 10.0276 50.8576C10.4257 52.3331 11.1227 53.711 12.0753 54.9061C13.0279 56.1011 14.2158 57.0878 15.5654 57.8048C16.915 58.5218 18.3975 58.954 19.921 59.0745C21.4445 59.195 22.9766 59.0013 24.4221 58.5053C25.8676 58.0094 27.1959 57.2218 28.3245 56.1913C25.7943 54.5828 23.6784 52.401 22.1482 49.8227C20.618 47.2443 19.7164 44.3419 19.5165 41.3503Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.517 57.9002C47.5163 57.608 47.4942 57.3162 47.451 57.0272C44.7268 58.4499 41.6984 59.1911 38.625 59.1872C35.3921 59.1915 32.2115 58.3717 29.3835 56.8052C29.3167 57.1663 29.283 57.5329 29.283 57.9002C29.283 61.9907 33.3645 65.3057 38.4 65.3057C43.4355 65.3057 47.517 61.9847 47.517 57.9002Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M60.636 58.1264C58.6799 59.0085 56.5109 59.3078 54.3889 58.9886C52.2669 58.6693 50.282 57.745 48.672 56.3264C45.6539 58.1961 42.1724 59.1834 38.622 59.1764C34.9896 59.182 31.4317 58.1466 28.3695 56.1929C26.7518 57.6696 24.7352 58.6375 22.5711 58.9757C20.407 59.3139 18.1912 59.0076 16.2 58.0949C8.59651 65.6384 6.0285 75.1499 6.0285 75.1499H70.7716C70.7716 75.1499 68.211 65.6639 60.636 58.1264Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M45.9135 45.3267C45.9135 45.3267 44.451 49.8867 38.4 49.8867C32.349 49.8867 30.8865 45.3267 30.8865 45.3267" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.6825 37.0572C33.4246 36.315 33.4246 35.1117 32.6825 34.3695C31.9403 33.6273 30.7369 33.6273 29.9947 34.3695C29.2525 35.1117 29.2525 36.315 29.9947 37.0572C30.7369 37.7994 31.9403 37.7994 32.6825 37.0572Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.3132 36.1478C47.5527 35.1259 46.9185 34.1033 45.8965 33.8638C44.8746 33.6243 43.852 34.2586 43.6125 35.2805C43.373 36.3024 44.0073 37.325 45.0292 37.5645C46.0512 37.804 47.0737 37.1697 47.3132 36.1478Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )
        break;

        case 'icon_piggy_bank':
        Icon = (
          <svg width="77" height="52" viewBox="0 0 77 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.3764 29.75H70.4585C69.2897 27.0938 67.5897 24.743 65.4913 22.7773L68.0014 12.75H63.7514C59.8468 12.75 56.3936 14.543 54.0561 17.3055C53.0468 17.1594 52.0507 17 51.0015 17H34.0015C23.7218 17 15.1554 24.3047 13.1765 34H7.43901C5.47339 34 3.91949 32.207 4.31792 30.175C4.61011 28.6609 6.0312 27.625 7.57183 27.625H7.70464C8.14292 27.625 8.50151 27.2664 8.50151 26.8281V24.1719C8.50151 23.7336 8.14292 23.375 7.70464 23.375C3.91949 23.375 0.546053 26.0844 0.0679287 29.8297C-0.516446 34.3719 3.01636 38.25 7.43901 38.25H12.7515C12.7515 45.1828 16.1249 51.2789 21.2515 55.157V65.875C21.2515 67.0437 22.2077 68 23.3765 68H31.8765C33.0452 68 34.0015 67.0437 34.0015 65.875V59.5H51.0015V65.875C51.0015 67.0437 51.9577 68 53.1265 68H61.6265C62.7952 68 63.7514 67.0437 63.7514 65.875V55.157C65.3186 53.975 66.7132 52.5805 67.9085 51H74.3764C75.5452 51 76.5014 50.0438 76.5014 48.875V31.875C76.5014 30.7062 75.5452 29.75 74.3764 29.75ZM57.3765 38.25C56.2077 38.25 55.2515 37.2938 55.2515 36.125C55.2515 34.9562 56.2077 34 57.3765 34C58.5452 34 59.5015 34.9562 59.5015 36.125C59.5015 37.2938 58.5452 38.25 57.3765 38.25ZM34.0015 12.75H51.0015C51.7187 12.75 52.4226 12.8031 53.1132 12.8563C53.1132 12.8164 53.1265 12.7898 53.1265 12.75C53.1265 5.71094 47.4155 0 40.3765 0C33.3374 0 27.6265 5.71094 27.6265 12.75C27.6265 13.0289 27.6929 13.2945 27.7062 13.5734C29.7249 13.0555 31.8234 12.75 34.0015 12.75Z" fill="black"/>
          </svg>
        )
        break;
      
      default:
        Icon = (
          <svg className="sprite" width="130" height="130" viewBox="0 0 130 130">
            <circle cx="64" cy="64" r="50" fill="rgba(51,51,51,.9)" strokeWidth="2" stroke="rgba(51,51,51,.9)"/>
            <path d="M99 37c0-3-3-6-6-8-6-3-17-3-26 0-6 2-17 7-25 14-8 8-10 15-9 17 1 8 9 13 15 17 2 2 3 3 5 4-4 1-13 7-16 12-2 5-1 8 0 9 1 2 2 3 3 3 2 1 3 1 4 1 6 0 11-3 15-8 3-5 3-12 2-16h7c5 1 7 3 8 4 2 2 2 3 2 4-1 2-2 4-3 4s-1 1-1 2 1 0 2 0 5-2 5-6c0-3-1-6-3-8-3-3-7-5-12-5-3 0-6 1-8 1-2-2-4-4-6-5-5-4-10-8-10-13 0-7 7-14 20-21 12-5 22-6 27-4 2 1 3 2 4 3 1 2 0 5-2 8-3 5-10 12-21 13-7 1-10-2-10-2-1-1-2-2-3-1-1 0 0 2 0 2 1 1 3 4 7 5 3 1 10 2 19-2 11-4 19-16 16-24zM55 91v2l-3 3c-2 3-6 4-8 3v-1c-1-1 0-4 3-7s8-6 9-6c0 2 0 4-1 6z" fill="#FFF"/>
          </svg>
        )
        break;
    }
  
    return Icon
}


export default Icons