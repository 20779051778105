import React, { Component } from 'react';

import { withFirebase } from '../Firebase/FirebaseContext';

const INITIAL_STATE = {
  address: '',
  city: '',
  postCode: '',
  error: null,
  initFirebase: false,
  theUser: '',
  users: [],
};

class UserInfo extends Component {
  constructor(props) {
    super(props);

    // this.state = { ...INITIAL_STATE };
    this.state = {
      address: '',
      city: '',
      postCode: '',
      error: null,
      initFirebase: false,
      user: '',
      users: []
    }
  }

  // onSubmit = event => {
  //   const { address, city, postCode } = this.state;

  //   event.preventDefault();

  //   console.log(` LogName : `, event, address, city, postCode)
  //   this.props.firebase
  //     .doInfoUpdate(address, city, postCode)
  //     .then(() => {
  //       this.setState(() => ({ ...INITIAL_STATE }));
  //     })
  //     .catch(error => {
  //       this.setState({ error });
  //     });

    
  // };

  firebaseInit = () => {
    // const { users } = this.state
// console.log(this.props.firebase);

    const { firebase } = this.props

    let authUser = firebase.auth.currentUser.uid

    

    if (firebase && !this.state.initFirebase && this.state.users) {
      firebase.getSingleUser(authUser)
        .then(doc => {
          let user = doc.data()
          this.setState({
            user: user
          })
        })
        this.setState({ 
          initFirebase: true,
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
    
  }

  componentDidUpdate() {
    this.firebaseInit();
    // console.log(` users : `, this.state.users)
  }



  render() {

    return (
<div>
  <h1>Strona witajaca uzytkownika na profilu.</h1>
  <br />
  <br />
  <br />
  <br />
  <h2>Witaj, {this.state.user.name}</h2>
</div>
    
    );
  }
}

export default withFirebase(UserInfo);


// var docRef = db.collection("cities").doc("SF");

// docRef.get().then(function(doc) {
//     if (doc.exists) {
//         console.log("Document data:", doc.data());
//     } else {
//         // doc.data() will be undefined in this case
//         console.log("No such document!");
//     }
// }).catch(function(error) {
//     console.log("Error getting document:", error);
// });