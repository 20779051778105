import React from 'react'
import styled from 'styled-components'

import { StaticQuery, graphql } from 'gatsby'

import { Grid, Icon, Menu } from 'semantic-ui-react'
// import { Grid, Icon, Item, Label, List, Menu, Segment } from 'semantic-ui-react'

import { Link } from '../Elements'
import * as Theme from '../Elements/theme'

const StyledIcon = styled(Icon)`
  color: ${ Theme.Colors.offWhite };
  padding-left: ${props => props.social ? '18px' : 'initial' };
  padding-right: ${props => props.social ? '18px' : 'initial' };
`

const HeaderKontakt = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsContactInfo {
        emailPrimary
        phonePrimary
        phoneSecondary
        socialFacebook
        socialInstagram
        socialYoutube
      }
    }
  `}
  render={data => {

    const { phonePrimary, phoneSecondary, emailPrimary, socialFacebook, socialInstagram, socialYoutube } = data.datoCmsContactInfo

    return (

      // <Grid verticalAlign='middle'>
      //   <Grid.Row style={{ backgroundColor: Theme.Colors.darkBlue, paddingRight: '2rem', borderRadius: '0px', borderTop: '0px', paddingTop: '20px', paddingBottom: '6px' }}>
      //     <Grid.Column computer={7} tablet={5}>
      //     </Grid.Column>
      //     <Grid.Column computer={3} tablet={3}>
      //       <StyledIcon name='phone' size='large' /> 
      //       <Link contact header href={ `tel:${phonePrimary}` }>
      //         { phonePrimary }
      //       </Link>
      //     </Grid.Column>
      //     <Grid.Column computer={3} tablet={4}>
      //       <StyledIcon name='mail' size='large' />
      //         <Link contact header href={ `mailto:${emailPrimary}` }>
      //           { emailPrimary } 
      //         </Link>
      //     </Grid.Column>
      //     <Grid.Column computer={3} tablet={4}>
      //     <Link contact href={ socialFacebook }>
      //        <StyledIcon link social name='facebook square' size='large' />
      //      </Link>
      //      <Link contact href={ socialInstagram }>
      //        <StyledIcon link social name='instagram' size='large' />
      //      </Link>
      //      <Link contact href={ socialYoutube }>
      //        <StyledIcon link social name='youtube' size='large' />
      //      </Link>
      //     </Grid.Column>
      //   </Grid.Row>
      // </Grid>



      <Menu attached='top' widths={4} stackable text style={{ backgroundColor: Theme.Colors.darkBlue, paddingRight: '0.75rem', borderRadius: '0px', borderTop: '0px' }}>
        <Menu.Menu position='right' style={{ paddingRight: '1rem'}}>
          <Menu.Item>
            <StyledIcon name='phone' size='large' /> 
            <Link contact header href={ `tel:${phonePrimary}` }>
              { phonePrimary }
            </Link>
          </Menu.Item>
          <Menu.Item>
            <StyledIcon name='phone' size='large' /> 
            <Link contact header href={ `tel:${phoneSecondary}` }>
              { phoneSecondary }
            </Link>
          </Menu.Item>
          <Menu.Item>
            <StyledIcon name='mail' size='large' />
            <Link contact header href={ `mailto:${emailPrimary}` }>
              { emailPrimary } 
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link contact href={ socialFacebook }>
              <StyledIcon link social='true' name='facebook square' size='large' />
            </Link>
            <Link contact href={ socialInstagram }>
              <StyledIcon link social='true' name='instagram' size='large' />
            </Link>
            <Link contact href={ socialYoutube }>
              <StyledIcon link social='true' name='youtube' size='large' />
            </Link>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }}
  />
)

export default HeaderKontakt