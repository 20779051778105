import styled from 'styled-components'
import { Item } from 'semantic-ui-react'
import { Font } from './theme'

const Family = Font.Family

const ItemDescription = styled(Item.Description)`
  &&& {
    font-family: ${ Family.SubHeading } !important;
    font-weight: 400;
    min-height: 5.6rem;
  }
`

const ItemHeader = styled(Item.Header)`
  &&& {
    font-family: ${ Family.SubHeading } !important;
  }
`

export { ItemDescription, ItemHeader }