import styled from "styled-components";
import { Grid } from "semantic-ui-react"
import { Colors, Font } from './theme'

const Family = Font.Family
const Size = Font.Size

const StyledGrid = styled(Grid)`
  &&& {
    background-color: ${props => props.subfooter ? Colors.darkBlue : 'inherit' }
    color: ${props => props.footer || props.subfooter ? Colors.grey : 'initial' }
    padding-top: ${props => props.subfooter ? '1.6rem' : 'initial' }
    padding-bottom: ${props => props.subfooter ? '1rem' : 'initial' }
    font-family: ${props => props.footer || props.subfooter ? Family.SmallText : Family.SubHeading }
    a {
      color: ${props => props.subfooter ? Colors.grey : 'initial' }
      &:hover {
        color: ${ Colors.offWhite }
      }
    }
  }
`

export default StyledGrid

  // , paddingTop: '2rem', paddingBottom: '1rem'