import 'firebase/firestore';

const prodConfig = {
  apiKey: "AIzaSyBT7e0VWxFHw_8dr2FMlKCfS6gSvuCWhuc",
  authDomain: "kapiteam-agencja-turystyczna.firebaseapp.com",
  databaseURL: "https://kapiteam-agencja-turystyczna.firebaseio.com",
  projectId: "kapiteam-agencja-turystyczna",
  storageBucket: "kapiteam-agencja-turystyczna.appspot.com",
  messagingSenderId: "114752603719"
};

const devConfig = {
  apiKey: "AIzaSyBT7e0VWxFHw_8dr2FMlKCfS6gSvuCWhuc",
  authDomain: "kapiteam-agencja-turystyczna.firebaseapp.com",
  databaseURL: "https://kapiteam-agencja-turystyczna.firebaseio.com",
  projectId: "kapiteam-agencja-turystyczna",
  storageBucket: "kapiteam-agencja-turystyczna.appspot.com",
  messagingSenderId: "114752603719"
};

const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    this.db = app.database();
    this.auth = app.auth();
    this.fstore = app.firestore();
    this.settings = { }
    this.fstore.settings(this.settings)
    this.app = app
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doDeleteUserAccount = (currentPassword) => {
    const { currentUser } = this.auth
    const credential = this.getCredential(currentPassword)

    return currentUser.reauthenticateAndRetrieveDataWithCredential(credential).then(() => {
      return this.fstore.collection('users').doc(currentUser.uid)
        .delete().then(() => {
          return true
        }).catch(error => {
          throw error
        }).then(() => {
          return currentUser.delete().then(() => {
            return true
          }).catch(error => {
            throw error
          })
        })
      }).catch(error => {
        throw error
      })
  }
  
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (currentPassword, newPassword) => {
    const { currentUser } = this.auth
    const credential = this.getCredential(currentPassword)

    return currentUser.reauthenticateAndRetrieveDataWithCredential(credential).then(() => {
      return currentUser.updatePassword(newPassword)
    })
  }

  doInfoUpdate = ( address, city, postCode ) => (
    this.fstore.collection('users').doc(this.auth.currentUser.uid)
      .update({
        address: address,
        city: city,
        postCode: postCode
      })
  )

  getCredential = (currentPassword) => {
    return this.app.auth.EmailAuthProvider.credential(
      this.auth.currentUser.email,
      currentPassword
    )
  }


  // *** User API ***
// Add new user to Firestore, and add info schema
  doCreateUser = (id, username, email, permission) => {
    // console.log(` creating : `, id, username, email, permission)
      this.fstore.collection('users').doc(id)
        .set({
          name: username,
          email: email,
          // addressOne: '',
          // addressTwo: '',
          // postCode: '',
          // phone: '',
          // pesel: '',
          permission: permission
        })

    }

  onceGetUsers = () => this.fstore.collection('users').get()

  getSingleUser = (user) => this.fstore.collection('users').doc(user).get()


  // *** Reservation API ***
  getUserReservations = (user) => this.fstore.collection('reservations').doc(user).get()


}


let firebase;

function getFirebase(app, auth, database, fstore) {
  if (firebase) {
    return firebase;
  }

  firebase = new Firebase(app, auth, database, fstore);

  return firebase;
}

export default getFirebase;
