import React, { Component } from 'react';

import { withFirebase } from '../Firebase/FirebaseContext';

import { Form, Input, Button, Segment } from 'semantic-ui-react'

const INITIAL_STATE = {
  address: '',
  city: '',
  postCode: '',
  error: null
};

class UserInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    const { address, city, postCode } = this.state;

    event.preventDefault();

    // console.log(this.props.firebase);


    // console.log(` LogName : `, event, address, city, postCode)
    this.props.firebase
      .doInfoUpdate(address, city, postCode)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState({ error });
      });

    
  };

  

  render() {

    return (

      <Form
      onSubmit={this.onSubmit}
      // loading={loading}
      // error={!!errors}
    >
      {/* {errors ? this.handleErrors(errors) : null} */}
      <Segment>
      <Form.Field required>
          <label htmlFor="name">Imię i nazwisko</label>
          <Input
            id="address"
            // fluid
            name="name"
            type="text"
            required
            autoFocus
            onChange={event => {
              this.setState({ [event.target.name]: event.target.value })
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label htmlFor="email">Address</label>
          <Input
            id="email"
            // fluid
            name="email"
            type="email"
            onChange={event => {
              this.setState({ [event.target.name]: event.target.value })
            }}
            required
          />
        </Form.Field>

        <Form.Field required>
          <label htmlFor="password">Hasło</label>
          <Input
            id="password"
            // fluid
            name="password"
            type="password"
            required
            onChange={event => {
              this.setState({ [event.target.name]: event.target.value })
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label htmlFor="password">Powtóż hasło</label>
          <Input
            id="password"
            // fluid
            name="password"
            type="password"
            required
            onChange={event => {
              this.setState({ [event.target.name]: event.target.value })
            }}
          />
        </Form.Field>
        <Form.Checkbox required label='Wyrażam zgode na przetwarzanie danych' />
        <Button type="submit" color="blue">
          Do it
        </Button>
      </Segment>
    </Form>

    );
  }
}

export default withFirebase(UserInfoForm);
