import React, { useState } from 'react'

import { withFirebase } from '../Firebase/FirebaseContext'
import FormField from '../Partials/FormField'
// import Sentry from '../Utilities/Sentry'

import { Button, Form, Icon, Message } from 'semantic-ui-react'

const DeleteAccount = ({ firebase }) => {

  const [confirm, setConfirm] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)

  const deleteUser = () => {
    firebase.doDeleteUserAccount(password)
      .then(() => {
        setConfirm(false)
        setErrMessage('')
        setError(false)
        setSuccess(true)
      }
      ).catch(error => {
        let msg
        switch (error.code) {
          case 'auth/wrong-password':
            msg = 'Podane hasło jest nieprawidłowe. Prosimy spróbować ponownie.'
            break
          case 'auth/network-request-failed':
            msg = 'Wystąpił błąd. Prosimy spróbować ponownie.'
            break
          default:
            break
        }
        setErrMessage(msg)
        setError(true)
      })
  }

  const handleChange = ({ target: { value } }) => {
    setError(false)
    setPassword(value)
  }

  const cancelDelete = () => {
    document.forms['password'].reset()
    setConfirm(false)
    setError(false)
    setPassword('')
  }

  return (
    <>
      <Message icon warning>
        <Icon color='red' name='times' />
        <Message.Content>
          <Message.Header>Permanentnie usuń Twoje konto</Message.Header>
          Po usunięciu konta, wszystkie dane związane z Twoim kontem zostaną usunięte. Nie ma możliwości przywrócenia danych.
        </Message.Content>
      </Message>
      <Button color='red' type='button' size='large' onClick={() => setConfirm(true)}
        style={{
          fontFamily: 'Playfair Display',
          fontWeight: '400',
          marginTop: '1rem'
        }}>
        Usuń Konto
      </Button>
      <Message icon info hidden={!confirm}>
        <Icon name='question'/>
        <Message.Content>
          <Message.Header style={{ marginBottom: '2rem' }}>Czy jesteś pewny/pewna?</Message.Header>
          <Form id="password" style={{ marginBottom: '1rem' }}>
            <FormField name="confirmPassword" type="password" label="Prosimy potwierdzić hasło" action={e => handleChange(e)} focus />
          </Form>
          <Button basic negative onClick={() => deleteUser()}>Tak, usuń konto</Button>
          <Button basic positive onClick={() => cancelDelete()}>Nie, wracamy!</Button>
        </Message.Content>
      </Message>
      <Message
        negative
        hidden={!error}
        header="Wystąpił błąd"
        content={errMessage}
      />
      <Message
        positive
        hidden={!success}
        header="Sukces"
        content="Twoje konto zostało usunięte. Dziękujemy."
      />
    </>
  )
}

export default withFirebase(DeleteAccount)