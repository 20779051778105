export const FontFamily = {
  Heading: 'Bangers',
  SubHeading: 'Playfair Display'
}

export const FontColor = {
  Heading: '#FFEE00',
  SubHeading: 'mediumseagreen'
}

export const Font = {
  Family: {
    Contact: 'Playfair Display',
    Heading: 'Bangers',
    SubHeading: 'Playfair Display',
    SmallText: 'Montserrat'
  },
  Size: {
    Contact: '1.25rem',
    Form: '1.1rem',
    Heading: '3.5rem',
    SubHeading: '2rem'

  }
}

export const Colors = {
  lightYellow: '#FFFCB7',
  yellow: '#FFEE00',
  green: 'mediumseagreen',
  mainBG: '#FFFFFF',
  darkBlue: '#2B3572',
  offWhite: '#FFFBF9',
  grey: '#CBCFD4'
}

export const theme = {
  breakpoints: {
    xxs: '320px',
    xs: '400px',
    ms: '520px',
    s: '620px',
    md: '780px',
    m: '900px',
    l: '1200px',
    xl: '1920px',
    xxl: '2200px'
  }
}