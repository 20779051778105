import React, { Component, useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby'

import Desktop from './Desktop'
import Mobile from './Mobile'

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: undefined
    }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  })

  render() {
    return (
      <>
        { this.state.windowWidth >= 1267 
          ? <Desktop data={this.props.data}>{this.props.children}</Desktop>
          : <Mobile data={this.props.data}>{this.props.children}</Mobile>
        }
      </>
      )
    }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsOffer {
            edges {
              node {
                uid
                categories {
                  name
                }
              }
            }
          }
      }
    `}
    render={data => <Navigation data={data} {...props} />}
  />
)