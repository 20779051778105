export const LANDING = '/';
export const SIGN_UP = '/rejestracja';
export const SIGN_IN = '/logowanie';
export const PASSWORD_FORGET = '/zresetuj-haslo';
export const HOME = '/home';
export const ABOUT = '/o-nas';
export const ACCOUNT = '/twoje-konto';
export const CONTACT = '/kontakt';
export const OFFERS = '/oferty';
export const RESORTS = '/osrodki';
export const GALLERY = '/galeria';
export const KADRA = '/kadra';
export const OPINIONS = '/opinie';
export const ATTRACTIONS = '/atrakcje';
export const FORPARENTS = '/strefa-rodzica';
export const DOWNLOADS = FORPARENTS + '/dokumenty-do-pobrania';
export const FAQ = FORPARENTS + '/odpowiedzi-na-pytania-rodzicow';
export const PROMO = FORPARENTS + '/promocje';
export const CONDITIONS = FORPARENTS + '/warunki-uczestnictwa';






