import React, { Component } from 'react';

import { withFirebase } from '../Firebase/FirebaseContext'
import FormField from '../Partials/FormField'

import { Button, Form, Segment, Message } from 'semantic-ui-react'

const INITIAL_STATE = {
  oldPassword: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  errMessage: '',
  success: false,
}

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { oldPassword, passwordOne, passwordTwo } = this.state

    event.preventDefault()

    if (passwordOne === passwordTwo) {
      this.props.firebase
        .doPasswordUpdate(oldPassword, passwordOne)
        .then(() => {
          this.setState(() => ({ ...INITIAL_STATE }))
          this.setState({ success: true })
          document.forms['passwordChange'].reset()
        })
        .catch(error => {
          let errMessage
          switch (error.code) {
            case 'auth/weak-password':
              errMessage = 'Nowe hasło powinno mieć nie mniej niż 6 znaków.'
              break
            case 'auth/wrong-password':
              errMessage = 'Poprzednie hasło nie zgadza się z hasłem użytym do rejestracji. Prosimy spróbować ponownie.'
              break
            case 'auth/network-request-failed':
              errMessage = 'Wystąpił błąd. Prosimy spróbować ponownie.'
              break
            default:
              break
          }
          this.setState({ 
            error, 
            errMessage: errMessage
          })
        })
    } else {
      this.setState({ 
        error: true,
        errMessage: 'Wpisane hasła powinny być identyczne. Prosimy spróbować ponownie.'
      })
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
    if (this.state.error) this.setState({ error: false })
    if (this.state.success) this.setState({ success: false })
  }

  render() {
    const { error, errMessage, success } = this.state

    return (
      <Form id="passwordChange" onSubmit={this.onSubmit}>
        <Segment padded textAlign='left'>
          <FormField name="oldPassword" type="password" label="Poprzednie hasło" action={e => this.handleChange(e)} focus />
          <FormField name="passwordOne" type="password" label="Nowe hasło" action={e => this.handleChange(e)} />
          <FormField name="passwordTwo" type="password" label="Powtórz nowe hasło" action={e => this.handleChange(e)} />
          <Button type="submit" color="blue">
            Zresetuj hasło
          </Button>
          <Message 
            negative 
            hidden={!error}
            header="Wystąpił błąd"
            content={errMessage}
          />
          <Message
            positive
            hidden={!success}
            header="Sukces"
            content="Twoje hasło zostało zmienione. "
          />
        </Segment>
      </Form>
    )
  }
}

export default withFirebase(PasswordChangeForm)