import styled from 'styled-components'
import { Colors, Font } from './theme'

const Family = Font.Family
const Size = Font.Size

const StyledLink = styled.a`
  color: ${props => props.contact ? props.header ? Colors.offWhite : Colors.Contact : props.sec ? Colors.SubHeading : "mediumseagreen" };
  font-family: ${props => props.contact ? Family.Contact : Family.SubHeading }
  font-size: ${props => props.contact ? Size.Contact : Size.SubHeading }
  letter-spacing: ${props => props.contact ? '1px' : '0px' }
  padding-left: ${props => props.contact ? '5px' : '15px'};
  padding-right: ${props => props.contact ? '28px' : '5px'};
  font-weight: 400;
  &:hover {
    color: ${props => props.contact ? props.header ? Colors.offWhite : Colors.Contact : props.sec ? Colors.SubHeading : "mediumseagreen" };
  }
`

export default StyledLink