import styled from 'styled-components'
import { Card } from 'semantic-ui-react'
import { Font, theme } from './theme'

const Family = Font.Family

const CardHeader = styled(Card.Header)`
  &&& {
    font-family: ${ Family.SubHeading } !important;
  }
`

const CardWrapper = styled.div`
  padding-top: 2rem;
  @media (max-width: ${theme.breakpoints.xxl}),
    (max-device-width: ${theme.breakpoints.xxl}) {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  @media (max-width: ${theme.breakpoints.xl}),
    (max-device-width: ${theme.breakpoints.xl}) {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  @media (max-width: ${theme.breakpoints.l}),
    (max-device-width: ${theme.breakpoints.l}) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media (max-width: ${theme.breakpoints.m}),
    (max-device-width: ${theme.breakpoints.m}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: ${theme.breakpoints.xs}),
    (max-device-width: ${theme.breakpoints.xs}) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

export { CardHeader, CardWrapper }