import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'semantic-ui-react'

const FormField = ({ name, type, label, action, focus, noAutoComplete }) => (
  <Form.Field required>
    <label htmlFor={ name }>{ label }</label>
    <Input
      id={ name }
      name={ name }
      type={ type !== undefined ? type : name }
      autoComplete={ noAutoComplete ? `off` : `on` }
      autoFocus={ focus }
      onChange={ action }
      required
    />
  </Form.Field>
)

export default FormField

FormField.defaulProps = {
  focus: false
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([`email`,`password`,`text`]),
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  noAutoComplete: PropTypes.bool
}







